import {
    SET_TRANSACTION_DETAILS_DT,
    SET_PAYMENT_TYPE_DT,
    SET_CARD_DETAIL_DT,
    SET_TRANSFER_AMNT_DT,
    SET_FINAL_TRANSFER_AMNT_DT,
    SET_REWARDS_AMNT_DT,
    SET_WALLET_BAL_DT,
    SET_TRANSACTION_STATUS_DT,
    SET_ADDITIONAL_RESP_DT,
    SET_TRANSACTION_PAGE_VISITED,
    SET_DT_TRANS_FLOW,
    CLEAR_TRANS_STATE_DT,
    SET_CHALLENGE_PARAMS,
    SET_TRANSACTION_UNIQUE_ID_DT,
    SET_REDIRECT_ROUTE,
    SET_WALLET_BALANCE,
    SET_PAYMENT_ERROR
} from './actionTypes'

const initialState = {
    transfer_amount: '',
    dt_flow: '',
    payment_type: '',
    wallet_balance: '',
    wallet_balance_applied: '',
    destination_country: '',
    service_type: '',
    sub_service_type: '',
    service_provider: '',
    recipient_details: {},
    product: '',
    product_value: '',
    final_transfer_amount: '',
    card: '',
    rewards_amount: '',
    status: '',
    transaction_page_visited: false,
    additional_response: '',
    transactionUniqueId: '',
    challengeParams: {},
    redirectRoute: '',
    error: false,
    payment_error: '',
    payment_card_error: '',
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_TRANSACTION_DETAILS_DT:
            return { ...state, ...action.payload }
        case SET_TRANSACTION_PAGE_VISITED:
            return { ...state, transaction_page_visited: action.payload }
        case SET_PAYMENT_TYPE_DT:
            return { ...state, payment_type: action.payload }
        case SET_CARD_DETAIL_DT:
            return { ...state, card: action.payload }
        case SET_TRANSFER_AMNT_DT:
            return { ...state, transfer_amount: action.payload }
        case SET_FINAL_TRANSFER_AMNT_DT:
            return { ...state, final_transfer_amount: action.payload }
        case SET_REWARDS_AMNT_DT:
            return { ...state, rewards_amount: action.payload }
        case SET_WALLET_BAL_DT:
            return { ...state, wallet_balance_applied: action.payload }
        case SET_TRANSACTION_STATUS_DT:
            return { ...state, status: action.payload }
        case SET_ADDITIONAL_RESP_DT:
            return { ...state, additional_response: action.payload }
        case SET_DT_TRANS_FLOW:
            return { ...state, dt_flow: action.payload }
        case SET_TRANSACTION_UNIQUE_ID_DT:
            return { ...state, transactionUniqueId: action.payload }
        case SET_CHALLENGE_PARAMS:
            return { ...state, challengeParams: action.payload }
        case SET_REDIRECT_ROUTE:
            return { ...state, redirectRoute: action.payload }
        case SET_WALLET_BALANCE:
            return { ...state, wallet_balance: action.payload }    
        case CLEAR_TRANS_STATE_DT:
            return initialState
        case SET_PAYMENT_ERROR:
            return { ...state, error: action.payload }
        default:
            return state
    }
};

export default reducer;


